<template>
    <calculator-velo
        :url="url"
        :coupon_config="coupon_config"
        :calculator_color="calculator_color"
        :employer_grant="employer_grant"
        :color_button="color_button"
        :config_token="config_token"
        :portal="portal"
        :residual="residual"
        :auth_token="auth_token"
    ></calculator-velo>
</template>

<script>
import CalculatorVelo from './CalculatorVelo.vue'

export default {
    components: {
        CalculatorVelo,
    },
    props: {
        url: {
            type: String,
            default: '',
        },
        residual: {
            type: String,
            default: '16',
        },
        auth_token: {
            type: String,
            default: '',
        },
        coupon_config: {
            type: String,
            default:
                '',
        },
        calculator_color: {
            type: String,
            default: 'yellow',
        },
        employer_grant: {
            type: String,
            default:
                '{"protection":{"value":1,"disabled":false},"leasing":{"value":0,"disabled":false},"package":{"value":0,"disabled":false},"tax":{"value":0,"disabled":false}}',
        },
        color_button: {
            type: String,
            default: '{"color":{"background":"#ffd400","text":"#000000"}}',
        },
        portal: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style>
@import '../../webcomponent/calculator-velo.css';
@import '../../css/calculator.css';
</style>
